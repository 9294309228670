import cn from 'classnames';
import Image from 'next/legacy/image';
import { render } from 'storyblok-rich-text-react-renderer';

import styles from '../../styles/MediaText.module.css';

const MediaText = ({ blok }: any) => (
    <div className={styles.wrap}>
      <h3>{blok?.title}</h3>

      {(blok?.image || blok?.body) && (
        <div
          className={cn(
            styles.content,
            blok?.alignment === 'Image Left' ? styles.left : styles.right,
            blok.image.filename ? styles.has_image : ''
          )}
        >
          {blok.image.filename && (
            <div className={styles.img}>
              <Image
                src={blok.image.filename}
                alt={blok.image.alt}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
          )}

          {blok?.body && (
            <div className={styles.body}>{render(blok?.body)}</div>
          )}
        </div>
      )}
    </div>
);
export default MediaText;
