// @ts-nocheck
import '../styles/globals.css';

import { apiPlugin, storyblokInit } from '@storyblok/react';
import type { NextComponentType } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { FC } from 'react';
import { Fragment, useEffect } from 'react';

import MediaText from '../components/stories/MediaText';
import { GTM_ID, pageview } from '../lib/gtm';

type CustomNextComponent = NextComponentType & { Layout?: FC };
type CustomAppProps = AppProps & { Component: CustomNextComponent };

const components = {
  media_text: MediaText,
};

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
  use: [apiPlugin],
  components,
});

function MyApp({ Component, pageProps }: CustomAppProps) {
  const router = useRouter();
  const Layout: CustomNextComponent | typeof Fragment = Component.Layout
    ? Component.Layout
    : Fragment;

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  const cookieConsentInit = () => {
    /* eslint-disable */
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#88A537',
        },
        button: {
          background: '#105477',
        },
      },
      position: 'bottom-left',
      content: {
        href: 'https://meet-here.co.uk/cookies-policy',
      }
    });
    /* eslint-enable */
  };

  const snapEngageInit = () => {
    /* eslint-disable */
    SnapEngage.setCallback('StartChat', function (email, msg, type) {
      if (typeof ga !== 'undefined') {
        ga('send', 'event', 'SnapEngage', 'chatStarted', type);
      }
    });

    SnapEngage.setCallback('MessageSubmit', function (email, msg) {
      if (typeof ga !== 'undefined') {
        ga('send', 'event', 'SnapEngage', 'chatOfflineMessage', msg);
      }
    });
    /* eslint-enable */
  };

  return (
    <Layout>
      {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
        <>
          <Script
            id="gtag-base"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${GTM_ID}');
              `,
            }}
          />

          <Script
            src="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js"
            strategy="afterInteractive"
            onLoad={() => cookieConsentInit()}
          />

          <Script
            src="https://storage.googleapis.com/code.snapengage.com/js/6cb8233f-2242-4120-9f79-bf32e29f96e4.js"
            strategy="lazyOnload"
            onLoad={() => snapEngageInit()}
          />
        </>
      )}
      <Component {...pageProps} />
      <div id="modals" />
    </Layout>
  );
}
export default MyApp;
